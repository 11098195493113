@import 'variables.scss';

.new-user-phone {
  div {
    margin-bottom: 0px !important;
  }
  .input-group {
    padding: 0px;
  }
  .validation-message {
    margin-bottom: -28px !important;
  }
}

#securityQA {
  .MuiFormLabel-root.Mui-disabled {
    color: white;
  }
  input {
    color: white;
  }
}

.priority-pass-content {
  max-width: 400px !important;
}

.account-forced-limit-modal {
  max-width: 480px !important;
}

// Fast fix for tall modals
.react-modal-overlay {
  overflow-y: auto !important;
  .react-modal-content {
    top: 20%;
    transform: translateY(0%) !important;
  }
}
